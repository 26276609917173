import { defineStore } from 'pinia';
import moment from 'moment';
import { mapArrayToObjectsByKey } from '@/helpers/functions';
import { dashboardStatsChart } from '@/helpers/chart';
import session from '@/services/session';
import { useLandingPagesStore } from './landingPages';
import { useWebsitesStore } from './websites';

const isTonicUser = session.getUser()?.userType === 'tonic';

const FORMAT = 'YYYY-MM-DD';
// const DATE_RANGE = { startDate: moment().subtract(29, 'days').startOf('day').format(FORMAT), endDate: moment().endOf('day').format(FORMAT) };
const DATE_RANGE = { startDate: moment().startOf('day').format(FORMAT), endDate: moment().endOf('day').format(FORMAT) };

export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    dateRange: DATE_RANGE,
    lastUpdated: new Date().toISOString(),
    stats: {
      visits: {
        title: 'Page Views',
        value: 0,
        comparision: 0,
        graph: {},
      },
      ...(isTonicUser ? {
        uniqueVisits: {
          title: 'Visitors',
          value: 0,
          comparision: 0,
          graph: {},
        },
        clicks: {
          title: 'Clicks',
          value: 0,
          comparision: 0,
          graph: {},
        },
      } : {
        leads: {
          title: 'Leads',
          value: 0,
          comparision: 0,
          graph: {},
        },
        conversions: {
          title: 'Conversions',
          value: 0,
          comparision: 0,
          graph: {},
        },
      })
    },
    performance: {
      // filter: undefined,
      analytics: [],
    },
    websitePerformance: {
      // filter: undefined,
      analytics: [],
    },
    loaded: false,
  }),
  getters: {
    analyticsById: (state) => mapArrayToObjectsByKey(state.performance.analytics, 'id'),
    landersWithAnalytics(state) {
      const landingPagesStore = useLandingPagesStore();
      if (state.loaded && landingPagesStore.loaded) {
        return landingPagesStore.landers.filter((item) => !item.websiteId).map((item) => ({ ...item, analytics: state.analyticsById[item.id] }));
      }
      return [];
    },
    websiteAnalyticsById: (state) => mapArrayToObjectsByKey(state.websitePerformance.analytics, 'id'),
    websitesWithAnalytics(state) {
      const sitesStore = useWebsitesStore();
      if (state.loaded && sitesStore.loaded) {
        return sitesStore.websites.map((item) => ({ ...item, analytics: state.websiteAnalyticsById[item.id] }));
      }
      return [];
    },
    statsChart: (state) => (stat) => {
      const categories = Object.keys(state.stats[stat].graph).length ? state.stats[stat].graph.current.map((item) => item.date) : [];
      return { ...dashboardStatsChart, xaxis: { ...dashboardStatsChart.xaxis, categories } };
    },
    statsSeries: (state) => (stat) => (Object.keys(state.stats[stat].graph).length ? [
      { name: 'Current', data: state.stats[stat].graph.current.map((item) => item.count) },
      { name: 'Previous', data: state.stats[stat].graph.previous.map((item) => item.count) },
    ] : []),
  },
  actions: {
    addPerformanceAnalytics(payload) {
      this.performance.analytics.push(payload);
    },
    clearStore() {
      this.$reset();
    },
  },
});
