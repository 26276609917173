import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// router.beforeEach((to, from, next) => {
//   if ((from && to && from.name !== to.name) || !from) {
//     mp.track(mpConfig.PAGE_VIEW, { page: camelCaseToSpaces(to.name).trim(), ...to.query });
//   }
//   next();
// });

export default router;
